import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["root"]

  initialize() { 
    //     console.log(selector.foundation.adapter.getSelectAnchorAttr('max'));
    //     console.log(selector.foundation.adapter.getAnchorElement());
    //     console.log(selector.foundation.adapter.getMenuItemTextAtIndex(selector.selectedIndex))
  }

  onClick(e) {
    let phoneInput = this.rootTarget.querySelector("#user_phone");

    phoneInput.setAttribute("maxlength", e.currentTarget.dataset.max);
  }

}

const getMethods = (obj) => {
  let properties = new Set()
  let currentObj = obj
  do {
    Object.getOwnPropertyNames(currentObj).map(item => properties.add(item))
  } while ((currentObj = Object.getPrototypeOf(currentObj)))
  return [...properties.keys()].filter(item => typeof obj[item] === 'function')
}

